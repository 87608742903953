import type { FileKind } from './models';

export const VIDEO_MIME_TYPES = [
  'video/mp4',
  'video/mov',
  'video/quicktime',
  'video/mpeg',
  'video/avi',
  'video/x-msvideo',
] as const;

export const WEB_ALLOWED_VIDEO_MIME_TYPES = ['video/mp4'] as const;

export const AUDIO_MIME_TYPES = ['audio/mp4', 'audio/mpeg', 'audio/m4a', 'audio/x-m4a'] as const;
export const IMAGE_MIME_TYPES = [
  'image/png',
  'image/jpeg',
  'image/svg+xml',
  'image/gif',
  'image/bmp',
  'image/webp',
  'image/heif',
  'image/heic',
] as const;
export const ALL_MIME_TYPES = [...VIDEO_MIME_TYPES, ...AUDIO_MIME_TYPES, ...IMAGE_MIME_TYPES] as const;

export type SupportedMimeType = (typeof ALL_MIME_TYPES)[number];

export function getFileKindFromMimeType(mimeType: string): Omit<FileKind, 'all'> | undefined {
  const mimeTypeLower = mimeType.toLowerCase();
  switch (true) {
    case VIDEO_MIME_TYPES.includes(mimeTypeLower):
      return 'video';
    case AUDIO_MIME_TYPES.includes(mimeTypeLower):
      return 'audio';
    case IMAGE_MIME_TYPES.includes(mimeTypeLower):
      return 'image';
    default:
      return undefined;
  }
}

export function getMimeTypesFromFileKind(fileKind: FileKind) {
  switch (fileKind) {
    case 'video':
      return VIDEO_MIME_TYPES;
    case 'audio':
      return AUDIO_MIME_TYPES;
    case 'image':
      return IMAGE_MIME_TYPES;
    case 'all':
      return ALL_MIME_TYPES;
  }
}

const FILE_EXTENSIONS = {
  'image/jpeg': '.jpg',
  'image/png': '.png',
  'image/gif': '.gif',
  'image/bmp': '.bmp',
  'image/webp': '.webp',
  'image/heif': '.heif',
  'image/heic': '.heic',
  'image/svg+xml': '.svg',
  'video/mov': '.mov',
  'video/quicktime': '.mov',
  'video/mpeg': '.mpeg',
  'video/mp4': '.mp4',
  'video/avi': '.avi',
  'video/x-msvideo': '.avi',
  'audio/mp4': '.mp4',
  'audio/mpeg': '.mpeg',
  'audio/m4a': '.m4a',
  'audio/x-m4a': '.m4a',
} as const satisfies Record<(typeof ALL_MIME_TYPES)[number], string>;

export function getFileExtensionForMimeType(mimeType: string): string {
  return FILE_EXTENSIONS[mimeType as keyof typeof FILE_EXTENSIONS] ?? '.' + mimeType.split('/').pop();
}

export const ALLOWED_IMAGE_FILE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp', 'heic'] as const;
