<script lang="ts" setup>
import { close } from 'ionicons/icons';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/vue';
import { computed, ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import apiRequest from '@/services/apiRequest';
import type { Squad } from '@/models/squad';
import type { Player } from '@/models/player';

const props = defineProps<{ playerId?: string; teamId: string; matchFixtureId?: string }>();

const emit = defineEmits<{ (e: 'player-selected', player: Player): void }>();

const modal = ref();

const closeModal = () => modal.value?.$el.dismiss();

const onSelectPlayer = (player: Player) => {
  emit('player-selected', player);
  closeModal();
};

// TODO: Fetch match lineup and prefer it over the general set of players of the team
// const { data: matchLineup, refetch: refetchMatchLineup } = useQuery({
//   queryKey: ['matchLineup', props.matchFixtureId],
//   queryFn: async () => {
//     const result = await apiRequest.methods.get(
//       `api/data/teams/${teamId.value}/unfold?includes=Clubs`,
//       'cloud',
//     );
//     if (result.error !== null) {
//       throw result.error;
//     }
//     return result.data;
//   },
// });

const { data: squadId } = useQuery({
  queryKey: ['squadId', props],
  queryFn: async () => {
    const result = await apiRequest.methods.get<string[]>(`api/data/teams/${props.teamId}/squads/ids`, 'cloud');
    if (result.error !== null) {
      throw result.error;
    }

    // Apparently, the first element is the current squad ID.
    // TODO: That's probably not always correct.
    return result.data[0];
  },
});

const { data: squad } = useQuery({
  enabled: () => !!squadId.value,
  queryKey: ['squad', squadId],
  queryFn: async () => {
    const result = await apiRequest.methods.get<Squad<'players'>>(
      `api/data/squads/${squadId.value}/unfold?includes=Players`,
      'cloud',
    );
    if (result.error !== null) {
      throw result.error;
    }
    return result.data;
  },
});

const availablePlayers = computed(() =>
  squad.value?.playerModels?.toSorted((a, b) =>
    a.fullName.split(' ').pop()!.localeCompare(b.fullName.split(' ').pop()!),
  ),
);
</script>

<template>
  <ion-modal ref="modal">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="closeModal()">
            <ion-icon :icon="close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Spieler ändern</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="m-2" v-if="availablePlayers">
        <ion-label
          v-for="player in availablePlayers"
          :key="player.uid"
          class="lineup__name"
          @click="onSelectPlayer(player)"
        >
          <div class="rounded-full bg-white overflow-hidden inline-block align-middle ml-1 mr-1 w-11">
            <img class="" :src="player.profilePictureUrl" />
          </div>
          <span class="lineup__jersey">{{ player.jerseyNumber }}</span>
          <span class="lineup__ellipsis font-bold">{{ player.fullName }}</span>
        </ion-label>
      </div>
    </ion-content>
  </ion-modal>
</template>

<style lang="scss" scoped>
.lineup__name {
  padding: 0.5rem;
  border-radius: 0.5rem;
  align-items: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 0 1px #0ff;
  }

  &:nth-child(odd) {
    background: rgba(255, 255, 255, 0.09);
  }
}

ion-toolbar {
  ion-button {
    ion-icon {
      font-size: 1.5rem;
      margin-bottom: 0;
    }
  }
}
</style>
